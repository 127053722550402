@import "./../../Styles/variaveis.module.scss";
@import "./../../Styles/breakpoints.scss";

.struturaFormInputs {
  width: 100%;

  .input{
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 2vh;
    & :focus {
        border: solid 1px $cor-secundaria ;
        cursor: pointer;

      }
      input {
        width: 80%;
        background-color: $cor-primaria;
        font-size: 15px;
        box-shadow: 0 0 0 0;
        border: solid 1px $cor-quarternaria;
        outline: 0;
        padding: 15px;
        border-radius: 10px 0px 0px 10px;
        color: $cor-terciaria;
      }
    }

    .iconPhone{
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0px 10px 10px 0px;
      background-color: $cor-secundaria;
      width: 20%;

    }

  .nota {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border: solid $cor-quarternaria 1px;
    margin-bottom: 2vh;

    .resposta {
      color: $cor-secundaria;
    }
  }

  .struturaCollapse {
    margin-bottom: 2vh;
  }

  .icon {
    color: $cor-terciaria;
  }
}
