@import "./../../Styles/variaveis.module.scss";
@import "./../../Styles/breakpoints.scss";

.home {
  display: flex;
  align-items: center;
  background-image: url("./../../Assests/Frame\ 2\ \(2\).png");
  height: 100vh;
  border-radius: 5px;
  background-size: cover;

  .opacity {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .fundoCard {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80%;
    height: 70%;
    gap: 3vh;

    .logoContainer {
      display: flex;
      justify-content: center;

      .logo {
        width: 120px;
      }
    }

    p {
      font-weight: 500;
      font-size: 25px;
      text-align: center;
      margin-bottom: 2vh;
    }
    .subTitulo {
      color: $cor-secundaria;
      font-weight: 200;
      font-size: 20px;
    }

    .botaoComecar {
      color: $cor-terciaria;
      padding: 15px;
      width: 100%;
      height: 10%;
      border: solid $cor-secundaria 2px;
      border-radius: 10px;
    }
  }
}

@media (min-width: $tablet) {
  .botaoComecar {
    width: 60% !important;
  }

  p{
    margin-bottom: 0 !important;
  }
}
